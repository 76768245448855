<script>
import lottie from 'lottie-web';
import animationData from '@/assets/animations/home1.json';
import animationDataMobile from '@/assets/animations/homemobile.json';

export default {
  name: 'licenseagreement',

  mounted() {
    const params = {
      container: this.$refs.bg,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
    };

    lottie.loadAnimation(params);

    params.container = this.$refs.bgmobile;
    params.animationData = animationDataMobile;

    lottie.loadAnimation(params);
  },
};

</script>

<template>
  <div class="licenseAgreement wrap">
    <the-nav>
      <router-link active-class="is-active" :to="{ name: 'home' }">
        Hire now
      </router-link>
    </the-nav>

    <header class="licenseAgreement_header">

      <h1 class="title is-2">End User License Agreement</h1>

      <div class="bg" ref="bg"></div>
      <div class="bgmobile" ref="bgmobile"></div>
    </header>

    <body>
      <div>
        <p>Please read this End-User License Agreement carefully before clicking the "I Agree" button, downloading or using Heroes Jobs.</p>
      </div>

      <div>
        <h2>Interpretation and Definitions</h2>
        <div>
          <h3>Interpretation</h3>
          <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.</p>
          <p>The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
        </div>
        <div>
          <h3>Definitions</h3>
          <p>For the purposes of this End-User License Agreement:</p>
          <ul>
            <li><span class="highlight">Agreement</span> means this End-User License Agreement that forms the entire agreement between You and the Company regarding the use of the Application. This Agreement has been created with the help of EULA Generator.</li>
            <li><span class="highlight">Application</span> means the software program provided by the Company downloaded by You to a Device, named Heroes Jobs</li>
            <li><span class="highlight">Company</span> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Heroes Jobs, Inc., 2219 Main St., Santa Monica, CA 90405.</li>
            <li><span class="highlight">Content</span> refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content.</li>
            <li><span class="highlight">Country</span> refers to: California, United States</li>
            <li><span class="hightlight">Device</span> means any device that can access the Application such as a computer, a cellphone or a digital tablet.</li>
            <li><span class="highlight">Third-Party Services</span> means any services or content (including data, information, applications and other products services) provided by a third-party that may be displayed, included or made available by the Application.</li>
            <li><span class="highlight">You</span> means the individual accessing or using the Application or the company, or other legal entity on behalf of which such individual is accessing or using the Application, as applicable.</li>
          </ul>
        </div>
      </div>

      <div>
        <h2>Acknowledgement</h2>
        <p>By clicking the "I Agree" button, downloading or using the Application, You are agreeing to be bound by the terms and conditions of this Agreement. If You do not agree to the terms of this Agreement, do not click on the "I Agree" button, do not download or do not use the Application.</p>
        <p>This Agreement is a legal document between You and the Company and it governs your use of the Application made available to You by the Company.</p>
        <p>The Application is licensed, not sold, to You by the Company for use strictly in accordance with the terms of this Agreement.</p>
        <p>You represent that you are over the age of 16. The Company does not target its Content to children or teenagers under 16, and the Company does not permit any user under 16 for using the Application.</p>
      </div>

      <div>
        <h2>User Generated Content</h2>
        <p>Heroes Jobs Inc. owns and/or operates various mobile apps, websites, social media and other online properties that permit users (hereinafter “Members” or “Users”) to submit or publish content (“User-Generated Websites”). Such websites may include, but are not limited to, message boards, blogs, social networks, and comment boards. By registering or using any User-Generated Websites, you accept and agree to the following Terms and conditions for User-Generated Content.</p>
        <p>Our social media page is a user-friendly page. We ask that you play nice and be polite. While we encourage the sharing of your comments, photos, videos, and links, please understand that the postings to our page do not necessarily represent the opinions of Heroes Jobs Inc. or its affiliates. Posts that we feel are offensive, inappropriate or not otherwise keeping with the spirit of the page, such as advertising, spam, posts that contain objectionable content, are abusive, off-topic, or contain vulgar language, posts that may violate the rights of others, or violate any of Heroes Jobs’s terms of use may be removed. We also reserve the right to block. If you have a material connection to Heroes Jobs Inc. (as an employee, agency, sponsored blogger/brand ambassador, etc.), please be sure to let everyone know that when you post.</p>
        <p>Use of Your Comments, Photos and Videos: We love to see all your comments, photos and videos. By submitting or uploading your story, comment, photo or video of you to our app, you are authorizing Heroes Jobs Inc. to use, publish, and otherwise reproduce, modify and distribute Your Content with or without your name in perpetuity, worldwide in any and all Heroes Jobs related media for any lawful purpose. These uses may include, but are not limited to, information, education, promotion or advertising of Heroes Jobs Inc. or its products via the Internet, web sites, mobile apps, and social media. Please do not upload or post any photos or videos unless you have the owner’s permission. If individuals other than you appear in the photo or video, you must have permission to use the photo or video from the individuals (or their parent’s or legal guardian’s permission if a minor).</p>
        <p>Submissions to this Page: Any material you submit to our page by upload, or otherwise, including any data, questions, comments, ideas, suggestions or the like (“Submissions”) are, and will be treated as, non-confidential and non-proprietary. Anything you submit or post becomes the property of Heroes Jobs Inc. and Heroes Jobs Inc. may use and reproduce such Submission freely, and for any purpose. Specifically, Heroes Jobs Inc. is free to use for any purposes whatsoever (including but not limited to the developing, manufacturing, advertising and marketing of products) any ideas, artwork, inventions, developments, suggestions or concepts contained in any Submission you post to our page. Any such use is without compensation to you. By making a Submission, you are also warranting that you own the material/content submitted and that Heroes Jobs Inc.’s use will not violate any third party's rights or otherwise place us in breach of any applicable laws. Heroes Jobs Inc. is under no obligation to use the information submitted. You understand that Heroes Jobs Inc. employs both internal and external resources which may have developed or may in the future develop ideas identical or similar to your Submissions and that Heroes Jobs Inc. is only willing to consider the Submissions on these terms.</p>
      </div>

      <div>
        <h2>License</h2>
        <div>
          <h3>Scope of License</h3>
          <p>The Company grants You a revocable, non-exclusive, non-transferable, limited license to download, install and use the Application strictly in accordance with the terms of this Agreement.</p>
          <p>The license that is granted to You by the Company is solely for your personal, non-commercial purposes strictly in accordance with the terms of this Agreement.</p>
        </div>
        <div>
          <h3>License Restrictions</h3>
          <p>You agree not to, and You will not permit others to:</p>
          <ul>
            <li>Remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of the Company or its affiliates, partners, suppliers or the licensors of the Application.</li>
          </ul>
        </div>
      </div>

      <div>
        <h2>Third-Party Services</h2>
        <p>The Application may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services.</p>
        <p>You acknowledge and agree that the Company shall not be responsible for any Third-party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. The Company does not assume and shall not have any liability or responsibility to You or any other person or entity for any Third-party Services.</p>
        <p>You must comply with applicable Third parties' Terms of agreement when using the Application. Third-party Services and links thereto are provided solely as a convenience to You and You access and use them entirely at your own risk and subject to such third parties' Terms and conditions.</p>
      </div>

      <div>
        <h2>Term and Termination</h2>
        <p>This Agreement shall remain in effect until terminated by You or the Company.</p>
        <p>The Company may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.</p>
        <p>This Agreement will terminate immediately, without prior notice from the Company, in the event that you fail to comply with any provision of this Agreement. You may also terminate this Agreement by deleting the Application and all copies thereof from your Device or from your computer.</p>
        <p>Upon termination of this Agreement, You shall cease all use of the Application and delete all copies of the Application from your Device.</p>
        <p>Termination of this Agreement will not limit any of the Company's rights or remedies at law or in equity in case of breach by You (during the term of this Agreement) of any of your obligations under the present Agreement.</p>
      </div>

      <div>
        <h2>Indemnification</h2>
        <p>You agree to indemnify and hold the Company and its parents, subsidiaries, affiliates, officers, employees, agents, partners and licensors (if any) harmless from any claim or demand, including reasonable attorneys' fees, due to or arising out of your: (a) use of the Application; (b) violation of this Agreement or any law or regulation; or (c) violation of any right of a third party.</p>
      </div>

      <div>
        <h2>No Warranties</h2>
        <p>The Application is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Application, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Application will meet your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</p>
        <p>Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Application, or the information, content, and materials or products included thereon; (ii) that the Application will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Application; or (iv) that the Application, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</p>
        <p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section 11 shall be applied to the greatest extent enforceable under applicable law. To the extent any warranty exists under law that cannot be disclaimed, the Company shall be solely responsible for such warranty.</p>
      </div>

      <div>
        <h2>Limitation of Liability</h2>
        <p>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Agreement and your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You for the Application or through the Application.</p>
        <p>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Application, third-party software and/or third-party hardware used with the Application, or otherwise in connection with any provision of this Agreement), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
        <p>Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to You.</p>
      </div>

      <div>
        <h2>Severability and Waiver</h2>
        <div>
          <h3>Severability</h3>
          <p>If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>
        </div>
        <div>
          <h3>Waiver</h3>
          <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Agreement shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.</p>
        </div>
      </div>

      <div>
        <h2>Product Claims</h2>
        <p>The Company does not make any warranties concerning the Application. To the extent You have any claim arising from or relating to your use of the Application, the Company is responsible for addressing any such claims, which may include, but not limited to: (i) any product liability claims; (ii) any claim that the Application fails to conform to any applicable legal or regulatory requirement; and (iii) any claim arising under consumer protection, or similar legislation.</p>
      </div>

      <div>
        <h3>United States Legal Compliance</h3>
        <p>You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a "terrorist supporting" country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.</p>
      </div>

      <div>
        <h2>Changes to this Agreement</h2>
        <p>The Company reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at the sole discretion of the Company.</p>
        <p>By continuing to access or use the Application after any revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, You are no longer authorized to use the Application.</p>
      </div>

      <div>
        <h2>Governing Law</h2>
        <p>The laws of the Country, excluding its conflicts of law rules, shall govern this Agreement and your use of the Application. Your use of the Application may also be subject to other local, state, national, or international laws.</p>
      </div>

      <div>
        <h2>Entire Agreement</h2>
        <p>The Agreement constitutes the entire agreement between You and the Company regarding your use of the Application and supersedes all prior and contemporaneous written or oral agreements between You and the Company.
You may be subject to additional terms and conditions that apply when You use or purchase other Company's services, which the Company will provide to You at the time of such use or purchase.</p>
      </div>

      <div>
        <h2>Contact Us</h2>
        <p>If you have any questions about this Agreement, You can contact Us: hello@heroes.jobs</p>
      </div>

    </body>
  </div>
</template>


<style lang="scss" scoped>

.licenseAgreement {
  &_header {
    text-align: center;
    margin-top: 90px;
    margin-bottom: 90px;
  }

  p {
    margin-bottom: 20px;
  };

  h2 {
    font-weight: bold;
    font-size: 22px;
    margin-top: 30px;
    margin-bottom: 15px;
    text-transform: uppercase;
  };

  h3 {
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  };

   li{
    margin-bottom: 10px;
  };

  ul {
    list-style-type: square;
    padding-left: 30px;
    margin-bottom: 0;
  };
}

.highlight {
  font-weight: bold;
};

</style>
